<template>
  <div class="login-page">
    <v-container>
      <v-row justify="center" class="mt-16">
        <v-col cols="12" lg="5">
          <v-card flat class="rounded-xl" dark outlined>
            <form @submit.prevent="login">
              <v-card-text class="pa-lg-12 pa-6">
                <div class="text-center">
                  <img src="../../assets/logo.svg" width="130"><br>
                </div>
                <h2 class="mb-2 mt-6">
                  Bienvenue chez OPUS ! 👋🏻
                </h2>
                <p class="mt-2">
                  Veuillez vous connecter à votre compte et commencer l'aventure
                </p>
                <v-alert dense v-if="errMessage" color="red" dark
                         icon="mdi-information-outline" border="left"
                         prominent>
                  {{ errMessage }}
                </v-alert>
                <v-text-field outlined
                              dense
                              prepend-inner-icon="mdi-email-outline"
                              type="email"
                              v-model="form.email"
                              :error-messages="errors.email"
                              @input='errors && errors.email ? errors.email= "": false'
                              label="Email *">
                </v-text-field>
                <v-text-field outlined
                              dense
                              prepend-inner-icon="mdi-lock-outline"
                              v-model="form.password"
                              :error-messages="errors.password"
                              :type="show ? 'text' : 'password'"
                              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                              @click:append="show = !show"
                              @input='errors && errors.password ? errors.password= "": false'
                              label="Mot de passe *">
                </v-text-field>
                <v-checkbox label="Se souvenir de moi" style="margin: -10px;"></v-checkbox>
                <v-btn type="submit"
                       depressed
                       :loading="btnLoading"
                       large block color="primary"
                       class="rounded-lg mt-3">
                  Connexion
                </v-btn>
                <div class="text-center mt-4">
                  Mot de passe oubliée ?
                  <router-link to="/" class="text-decoration-none">Cliquez ici</router-link>
                </div>
              </v-card-text>
            </form>
          </v-card>
          <p class="text-center white--text mt-10">© Copyright {{ new Date().getFullYear() }}
            <strong class="text-primary">OPUS</strong> Tous droits réservés.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      btnLoading: false,
      errors: {},
      form: {},
      show: false,
      errMessage: null
    }
  },
  methods: {
    login() {
      this.btnLoading = true
      this.errors = true
      axios.post(process.env.VUE_APP_BASE_URL + '/login', this.form).then((res) => {
        this.$store.commit('app/isLoggedIn', true)
        this.$store.commit('app/user', res.data.data)
        this.btnLoading = false
        this.$router.go(0);
      }).catch(err => {
        this.btnLoading = false
        this.errors = err.response.data.errors || {};
        this.errMessage = err.response.data.message || null;
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
</style>